
import DiscoverApi from '@/services/api/discover.api'
// import { isLocal } from '@/services/utility/is-local.utility'
// import { DEV_DISCOVER } from '@/config/develop.discover.config'
import { start } from '@/main'
import { shutdown } from '@/shutdown'

/**
 * Fetch configuration from DiscoverApi, then Start app.
 * We pass down an object containing the resolved configration to the start() function.
 * This is so we can immediately dispatch a pinia action and save the response in the store.
 *
 * In the event of failure, we use the hardcoded flags as a backup and then start() the app.
 */

DiscoverApi.getConfiguration().then(response => {
  /*
  let configuration = (import.meta.env.DEV && isLocal)
    ? { ...response.data, ...DEV_DISCOVER }
    : response.data
  */
  const configuration = response.data

  start({ configuration })
}).catch((error) => {
  shutdown({
    reason: error
  })
})
