// import store from '@/store'
import { has, path } from '@/services/utility/object.utility'
import { formQuery, filterStories, addScheduleQueryPath } from '@/services/utility/storyblok.schedule.utility'
import { groupBy } from '@/services/utility/array.utility.js'
import { CacheController } from '@/controllers/cache.controller'
import { supportsApplePay, paymentComponents as PAYMENT } from '@/services/helpers/payment-methods.helper.js'
import dayjs from 'dayjs'
import { SERVICE_TYPE } from '@/config/constants.js'

import { useCartStore } from '@/stores/cart'
import { useCacheStore } from '@/stores/cache'
import { useContentStore } from '@/stores/content'

export const ContentController = {
  getQuery: function (storeId) {
    const basket = useCartStore().basket

    if (!storeId) {
      storeId = basket.basketId && path(['storeInfo', 'storeId'], basket) ? basket.storeInfo.storeId : ''
    }

    let date = dayjs()
    if (
      basket.basketId &&
      basket.orderDueAt &&
      !dayjs(basket.orderDueAt).utcOffset(basket.orderDueAt).isBefore(dayjs())
    ) {
      date = dayjs(basket.orderDueAt).utcOffset(basket.orderDueAt)
    }

    const serviceType = basket.basketId && basket.serviceType ? basket.serviceType.toLowerCase() : SERVICE_TYPE.UNLOCALISED

    const query = formQuery(date, serviceType, storeId)

    return query
  },

  getOffers: async function (storeId, storeInCache = true) {
    const query = this.getQuery(storeId)
    return useContentStore().getOffers({ query, storeInCache })
  },

  getCoupons: async function (storeId, storeInCache = true) {
    const query = this.getQuery(storeId)
    return useContentStore().getCoupons({ query, storeInCache })
  },

  getBanner: async function () {
    const query = this.getQuery()
    return useContentStore().getBanner(query)
  },

  getBannerSecondary: async function () {
    const query = this.getQuery()
    return useContentStore().getBannerSecondary(query)
  },

  getBannerSub: async function () {
    const query = this.getQuery()
    return useContentStore().getBannerSub(query)
  },

  getSpinner: async function (caching = true) {
    if (caching && !CacheController.isCacheExpired(useCacheStore().spinner.expires_at)) {
      return useCacheStore().spinner?.cache?.content
    }

    const query = this.getQuery()
    return useContentStore().getSpinner(query)
  },

  getSpinTheWheel: async function () {
    const query = addScheduleQueryPath(this.getQuery())
    return useContentStore().getSpinTheWheel(query)
  },

  getFooter: async function () {
    return useContentStore().getNavigationMenuBySlug({
      slug: 'footer'
    })
  },

  getSnippet: async function (payload) {
    const query = this.getQuery()
    query.id = { in: payload.id }

    return useContentStore().getSnippet({ ...payload, query })
  },

  getSnippets: async function (payload) {
    const snippets = Object.entries(useCacheStore().snippets).filter(([key]) => key.includes(`${payload.path}/`))
    const query = this.getQuery()

    const cacheSnippet = (payload, path) => {
      // eslint-disable-next-line camelcase
      const expiry = payload?.content?.cache_expiry || 5
      useCacheStore().setCache({
        expires_at: CacheController.getTimeInXMinutes(expiry),
        cache: { name: payload?.name, content: payload?.content },
        path,
        cacheType: 'snippets'
      })
    }
    const expiredSnippets = snippets.some(([, value]) => CacheController.isCacheExpired(value.expires_at))

    if (!snippets?.length || expiredSnippets) {
      const response = await useContentStore().getSnippets({ ...payload, query })
      const stories = response.data.stories

      // Group snippets by id E.g. Top [5], Bottom [2]
      const groupedStories = groupBy(['content', 'id'], stories)

      // Find snippets that are currently cached, but weren't returned by Storyblok. E.g. Unpublished Top Snippet
      const missingSnippets = snippets?.filter(([key, val]) => {
        const ids = Object.keys(groupedStories)
        // If no id match
        if (!ids.includes(val?.cache?.content?.id)) {
          return val
        }

        // If multiple with same id, but different slugs
        const existing = groupedStories[val?.cache?.content?.id]?.find(snippet => snippet.full_slug === `snippets/${key}`)
        return !existing
      })

      // Remove from cache
      if (missingSnippets?.length) {
        missingSnippets.forEach(([key]) => cacheSnippet(null, key))
      }

      Object.entries(groupedStories).forEach(([key, val]) => {
        // Check if snippet has an id assigned to it
        if (key) {
          /* Filter & Prioritize snippets by their scheduling and filter out child snippets
           * E.g. Search for "snippets/menu/pizza" and filter out "snippets/menu/pizza/classics-range"
           */
          val = filterStories(val).filter(x => x.full_slug === `snippets/${payload.path}/${x.slug}`)
          if (val?.length) {
            const path = `${payload.path}/${val[0].content.id}`
            cacheSnippet(val[0], path)
          }
        } else {
          // Handle snippets with no id
          val.forEach(story => {
            const slug = story.full_slug.replace('snippets/', '')
            const path = `${slug.substring(0, slug.lastIndexOf(story.slug))}`
            cacheSnippet(story, path)
          })
        }
      })
    }

    return Object.fromEntries(snippets)
  },

  getIngredientUpsell: async function (payload) {
    const query = this.getQuery()
    query['product_ids.data'] = { in_array: payload.productId }

    return useContentStore().getIngredientUpsell({ ...payload, query })
  },

  getBaseUpsell: async function (payload) {
    const query = this.getQuery()
    query['product_ids.data'] = { in_array: payload.productId }

    return useContentStore().getBaseUpsell({ ...payload, query })
  },

  getSplashScreen: async function () {
    const query = this.getQuery()

    const splash = await useContentStore().getSplashScreen(query)
    const supportsPayment = (splash?.content?.payment_method?.includes(PAYMENT.APPLE_PAY.id) ? supportsApplePay() : true)

    if (splash && supportsPayment) {
      useContentStore().toggleSplash({ active: true, ...splash })
    } else {
      useContentStore().toggleSplash({ active: false })
    }

    return splash
  },

  getCampaignBySlug: async function (slug) {
    const query = this.getQuery()

    return await useContentStore().getCampaignBySlug({ query, slug })
  },

  getCokeCampaign: async function () {
    const query = this.getQuery()

    return await useContentStore().getCokeCampaign({ query })
  },

  formatCmsFooterContent: function (footer) {
    const navigation = []

    footer.navigation_items.forEach(item => {
      const nestedItemsLevel1 = []
      const nestedItemsLevel2 = []
      const nestedItemsLevel3 = []

      item.navigation_items.forEach(element => {
        nestedItemsLevel1.push({
          title: element.title,
          url: has('story', element.url) ? element.url.story.url : element.url.url,
          items: (() => {
            if (has('nested_navigation', element) && element.nested_navigation.length) {
              element.nested_navigation.forEach(element2 => {
                return nestedItemsLevel2.push({
                  title: element2.title,
                  url: has('story', element2.url) ? element2.url.story.url : element2.url.url,
                  items: (() => {
                    if (has('nested_navigation', element2) && element2.nested_navigation.length) {
                      element2.nested_navigation.forEach(element3 => {
                        return nestedItemsLevel3.push({
                          title: element3.title,
                          url: has('story', element3.url) ? element3.url.story.url : element3.url.url
                        })
                      })

                      return [...nestedItemsLevel3]
                    } else {
                      return []
                    }
                  })()
                })
              })

              return [...nestedItemsLevel2]
            } else {
              return []
            }
          })()
        })
      })

      navigation.push({
        items: nestedItemsLevel1
      })
    })

    return navigation
  },

  getFilterIcons: async function () {
    if (!CacheController.isCacheExpired(useCacheStore().filter_icons.expires_at)) {
      return useCacheStore().filter_icons
    }

    return useContentStore().getFilterIcons()
  },

  // Fetch the StoryBlok dictionary or Cached StoryBlok dictionary (Expire every 5 mins)
  getDictionary: async function (caching = true) {
    if (caching && !CacheController.isCacheExpired(useCacheStore().dictionary.expires_at)) {
      const cacheContent = useCacheStore().dictionary?.cache
      return cacheContent
    }

    const response = await useContentStore().getDictionary()
    return response
  },

  // Get the message from StoryBlok based on code value from backend
  codeToMessage: function (code, message) {
    // If null/empty/undefiend
    if (!code) { return message }

    switch (code) {
      // ZIPPAY (Default is use the message from BE)
      case 'AccountLock':
        return this.dictionaryToMessage('ORDER.ZIPPAY.ACCOUNT_LOCK', message)
      case 'ZipPayError':
        return this.dictionaryToMessage('ORDER.ZIPPAY.ZIPPAYERROR', message)
      case 'ZipPayGatewayError':
        return message // this.dictionaryToMessage("ORDER.ZIPPAY.GATEWAY_ERROR", message)
      case 'ZipPayOutOfStock':
        return message // this.dictionaryToMessage("ORDER.ZIPPAY.OUT_OF_STOCK", message) // has custom message from BE
      case 'DuplicateOrder':
        return message // this.dictionaryToMessage("ORDER.ZIPPAY.DUPLICATE_ORDER", message) // has custom message from BE
      case 'AmountInvalid':
        return message // this.dictionaryToMessage("ORDER.ZIPPAY.AMOUNT_INVALID", message)
      case 'AccountInoperative':
        return this.dictionaryToMessage('ORDER.ZIPPAY.ACCOUNT_INOPERATIVE', message)
      case 'FraudCheck':
        return this.dictionaryToMessage('ORDER.ZIPPAY.FRAUD_CHECK', message)
      case 'ZipInsufficientFunds':
        return this.dictionaryToMessage('ORDER.ZIPPAY.INSUFFICIENT_FUNDS', message)
      case 'ZipPayPaymentRequired':
        return this.dictionaryToMessage('ORDER.ZIPPAY.PAYMENT_REQUIRED', message)
      case 'ZipPayAccountUnavailable':
        return this.dictionaryToMessage('ORDER.ZIPPAY.ACCOUNT_UNAVAILABLE', message)

      // AFTERPAY
      case 'AfterpayBasketDiscrepancy':
        return this.dictionaryToMessage('ORDER.AFTERPAY.BASKET_DISCREPANCY', message)
      case 'AfterpayCompleteError':
        return this.dictionaryToMessage('ORDER.AFTERPAY.COMPLETE_ERROR', message)
      case 'AfterpayAuthoriseError':
        return this.dictionaryToMessage('ORDER.AFTERPAY.AUTHORISE_ERROR', message)
      case 'AfterpayCancelled':
        return this.dictionaryToMessage('ORDER.AFTERPAY.CANCELLED', message)
      case 'AfterpayBasketExpired':
        return this.dictionaryToMessage('ORDER.AFTERPAY.BASKET_EXPIRED', message)

      // PAYPAL
      case 'PayPalRejected':
        return this.dictionaryToMessage('ORDER.PAYPAL.REJECTED', message)

      // CART
      case 'CartError':
        return message // this.dictionaryToMessage("ORDER.CART.ERROR", message)
      case 'GatewayError':
        return this.dictionaryToMessage('ORDER.CART.GATEWAY_ERROR', message)
      case 'InsufficientFunds':
        return this.dictionaryToMessage('ORDER.CART.INSUFFICIENT_FUNDS', message)
      case 'CardLimitReached':
        return this.dictionaryToMessage('ORDER.CART.CARD_LIMIT_REACHED', message)
      case 'InvalidCard':
        return this.dictionaryToMessage('ORDER.CART.INVALID_CARD', message)
      case 'CardDeclined':
        return this.dictionaryToMessage('ORDER.CART.CARD_DECLINED', message)
      case 'AuthorisationFailed':
        return this.dictionaryToMessage('ORDER.CART.AUTHORISATION_FAILED', message)
      case 'CaptureFailed':
        return this.dictionaryToMessage('ORDER.CART.CAPTURE_FAILED', message)
      case 'AuthenticationFailed':
        return message // this.dictionaryToMessage("ORDER.CART.AUTHENTICATION_FAILED", message)
      case 'InvalidOrderDateTime':
        return this.dictionaryToMessage('ORDER.CART.INVALID_ORDER_DATETIME', message)
      case 'AuthorisationFailedInvalidCard':
        return this.dictionaryToMessage('ORDER.CART.AUTHORISATION_FAILED_INVALID_CARD', message)

      // BASKET
      case 'BasketItemPartNotFound':
        return this.dictionaryToMessage('BASKET.BASKET_ITEM_PART_NOT_FOUND', message)
      case 'BasketItemNotFound':
        return message // this.dictionaryToMessage("BASKET.BASKET_ITEM_NOT_FOUND", message)
      case 'UnmatchedBases':
        return this.dictionaryToMessage('BASKET.UNMATCH_BASES', message)
      case 'UnapprovedBasketTotal':
        return this.dictionaryToMessage('BASKET.UNAPPROVED_BASKET_TOTAL', message)
      case 'ValidateUnrealPizzas':
        return this.dictionaryToMessage('BASKET.EXCLUDE_UNREAL_PIZZA', message)
      case 'InvalidProduct':
        return message // this.dictionaryToMessage("BASKET.INVALID_PRODUCR", message) //
      case 'InvalidAlcohol':
        return message // this.dictionaryToMessage("BASKET.INVALID_PRODUCR", message) //
      case 'InvalidIngredients':
        return message // this.dictionaryToMessage("BASKET.INVALID_PRODUCR", message) //
      case 'BasketNotValid':
        return message // this.dictionaryToMessage("BASKET.INVALID_PRODUCR", message) //
      case 'OrderFailed':
        return this.dictionaryToMessage('BASKET.ORDER_FAILED', message)
      case 'BasketOrdered':
        return this.dictionaryToMessage('BASKET.BASKET_ORDERED', message)
      case 'BasketHasExpired':
        return this.dictionaryToMessage('BASKET.EXPIRED', message)

      // VOUCHER
      case 'InvalidVoucher':
        return message // this.dictionaryToMessage("VOUCHER.INVALID_VOUCHER", message)
      case 'VoucherUnavailableForFulfillmentType':
        return this.dictionaryToMessage('VOUCHER.VOUCHER_UNAVAILABLE_FOR_FULFILLMENT', message)
      case 'VoucherUnavailableForStore':
        return this.dictionaryToMessage('VOUCHER.VOUCHER_UNAVAILABLE_FOR_STORE', message)
      case 'VoucherUnavailableForFulfillmentDate':
        return this.dictionaryToMessage('VOUCHER.VOUCHER_UNAVAILABLE_FOR_FULFILLMENT_DATE', message)
      case 'VoucherAlreadyApplied':
        return this.dictionaryToMessage('VOUCHER.VOUCHER_ALREADY_APPLIED', message)
      case 'VoucherMinSpend':
        return this.dictionaryToMessage('VOUCHER.VOUCHER_MIN_SPEND', message)
      case 'VoucherMaxPerOrder':
        return message // this.dictionaryToMessage("VOUCHER.VOUCHER_MAX_PER_ORDER", message)
      case 'VoucherNotMatched':
        return this.dictionaryToMessage('VOUCHER.VOUCHER_NOT_MATCHED', message)
      case 'VoucherNotFound':
        return this.dictionaryToMessage('VOUCHER.VOUCHER_NOT_FOUND', message)
      case 'NoVoucher':
        return this.dictionaryToMessage('VOUCHER.NO_VOUCHER', message)
      case 'VoucherCriteriaNotMet':
        return this.dictionaryToMessage('VOUCHER.VOUCHER_CRITERIA_NOT_MET', message)
      case 'VoucherCannotStackup':
        return message // this.dictionaryToMessage("VOUCHER.VOUCHER_CANNOT_STACK_UP", message)
      case 'VoucherExpired':
        return this.dictionaryToMessage('VOUCHER.VOUCHER_EXPIRED', message)
      case 'CouponNotFound':
        return this.dictionaryToMessage('VOUCHER.COUPON_NOT_FOUND', message)
      case 'VoucherAutoRemove':
        return this.dictionaryToMessage('VOUCHER.AUTO_REMOVE_VOUCHER', message)

      // STORE
      case 'StoreNotFound':
        return this.dictionaryToMessage('STORE.STORE_NOT_FOUND', message)
      case 'StoreNotTrading':
        return message // this.dictionaryToMessage("STORE.STORE_NOT_TRADING", message)
      case 'StoreClosedOnlineOrders':
        return this.dictionaryToMessage('STORE.STORE_CLOSED_ONLINE_ORDERS', message)
      case 'StoreClosedForFurtherDeliveryOrders':
        return this.dictionaryToMessage('STORE.STORE_CLOSED_FURTHER_DELIVERY_ORDERS', message)
      case 'StoreClosedForFurtherPickupOrders':
        return this.dictionaryToMessage('STORE.STORE_CLOSED_FURTHER_PICKUP_ORDERS', message)
      case 'StoreClosedForFurtherOrders':
        return this.dictionaryToMessage('STORE.STORE_CLOSED_FURTHER_ORDERS', message)

      // PRODUCT
      case 'ProductNotFound':
        return this.dictionaryToMessage('PRODUCT.PRODUCT_NOT_FOUND', message)
      case 'NoCouponAccessError':
        return this.dictionaryToMessage('PRODUCT.NO_COUPON_ACCESS_ERROR', message)
      case 'IncompleteProduct':
        return this.dictionaryToMessage('PRODUCT.INCOMPLETE_PRODUCT', message)

      // Customer
      case 'EmailInUse':
        return this.dictionaryToMessage('CUSTOMER.EMAIL_IN_USE', message)
      case 'RecaptchaException':
        return this.dictionaryToMessage('CUSTOMER.RECAPTCHA_EXCEPTION', message)
      case 'InvalidPasswordResetToken':
            return this.dictionaryToMessage('CUSTOMER.INVALID_PASSWORD_RESET_TOKEN', message)

      // DEFAULT
      case 'InternalError':
        return this.dictionaryToMessage('ORDER.CART.INTERNAL_ERROR', message)
      case 'ServiceUnavailable':
        return this.dictionaryToMessage('BASKET.SERVICE_UNAVAILABLE', message)
      case 'InvalidRequest':
        return this.dictionaryToMessage('BASKET.INVALID_REQUEST', message)
      default:
        return message // Not handled error just return message.
    }
  },

  // Get the message from StoryBlok based on key value
  dictionaryToMessage: function (dictionaryCode, defaultMessage) {
    const data = useCacheStore().dictionary?.cache

    // If data is empty
    if (!data) { return defaultMessage }

    const message = data[dictionaryCode]

    // If message from StoryBlok is empty/undefiend/null
    if (!message) { return defaultMessage }

    return message
  },

  replaceKeysFromDictionaryCode (code, keyValue) {
    let message = this.dictionaryToMessage(code)
    // :minOrder:
    // :maxOrder:
    Object.entries(keyValue).forEach(([key, value]) => {
      const msgKey = ':' + key + ':'
      message = message.replace(msgKey, value)
    })

    return message
  },

  async getFeatureFlag (name, storeId) {
    try {
      const featureFlags = await useContentStore().getFeatureFlags()
      const flag = featureFlags.find(flag => flag.name === name)?.value
      return !flag || flag === 'enable' || flag.split(',').some(val => val.trim() && val.trim() === storeId.toString())
    } catch (err) {
      console.error(`Failed to fetch flag - ${name}`, err)
    }
  }
}
