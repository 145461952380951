import capitalize from 'lodash/capitalize'
import { Deploy } from 'cordova-plugin-ionic'
import { AppUpdate } from '@capawesome/capacitor-app-update'
import { dictionary } from '@/config/dictionary.config.js'
import { UiController } from '@/controllers/ui.controller'
import { Preferences } from '@capacitor/preferences'
import router from '@/router/'

export const DEFAULT_APPFLOW_CHANNEL = capitalize(import.meta.env.VITE_ENV)

export async function inAppUpdates () {
  try {
    const result = await AppUpdate.getAppUpdateInfo()

    if (result.updateAvailability !== 2) {
      return
    }

    if (result.immediateUpdateAllowed) {
      await AppUpdate.performImmediateUpdate()
    }
  } catch (err) {
    console.log(err)
  }
}

export async function liveUpdates (channel = DEFAULT_APPFLOW_CHANNEL) {
  try {
    await Deploy.configure({
      appId: '03cc38e9',
      channel
    })

    const { currentVersion, updatedVersion } = await checkForUpdates()

    // Live Update is happening and the app will be reloaded due to the "auto" setting
    if (!currentVersion || currentVersion.versionId !== updatedVersion.versionId) {
      await handleAppReload()
      return
    }

    await handleSavedUrl()
  } catch (err) {
    console.error(err)
    UiController.showSpinner({
      loading: true,
      message: err
    })
  }
}

async function checkForUpdates () {
  const currentVersion = await Deploy.getCurrentVersion()
  const updatedVersion = await Deploy.sync({ updateMethod: 'auto' }, percent => {
    UiController.showSpinner({
      loading: true,
      message: dictionary.CAPACITOR.UPDATES.DOWNLOADING(percent).text
    })
  })

  return { currentVersion, updatedVersion }
}

// Save current url path so it is preserved after reload
async function handleAppReload () {
  const url = window.location.pathname + window.location.search
  await Preferences.set({ key: dictionary.CAPACITOR.PREFERENCES.APP_INIT_URL, value: url })
}

// Get saved url if it exists and re-route to it
async function handleSavedUrl () {
  const initUrl = await Preferences.get({ key: dictionary.CAPACITOR.PREFERENCES.APP_INIT_URL })
  if (initUrl.value) {
    router.replace(initUrl.value)
    await Preferences.remove({ key: dictionary.CAPACITOR.PREFERENCES.APP_INIT_URL })
  }
}
