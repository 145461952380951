export const PRODUCT = {
  PRODUCT_TYPE: {
    UNKNOWN: 'Unkown Product Type',
    PIZZAS: 'Pizzas',
    SIDES: 'Sides',
    WINGS: 'Wings',
    DRINKS: 'Drinks',
    DESSERTS: 'Desserts',
    DEALS: 'Deals',
    MEALS: 'Meals',
    COUPONS: 'Coupons',
    WINGSSTREET: 'WingsStreet',
    SURCHARGES: 'SURCHARGES',
    HALFNHALF: 'HalfnHalf',
    ALCOHOL: 'Alcohol',
    MAIN: 'Main',
    MEATBALLS: 'Meatballs'
  },

  DISCRIMINATOR: {
    COUPON: 'Coupon',
    CUSTOMISABLE_PRODUCT: 'CustomisableProduct',
    DEAL: 'Deal',
    INGREDIENT_PRODUCT: 'IngredientProduct',
    STOCK_ITEM: 'StockItem',
    SURCHARGE: 'Surcharge'
  },

  INGREDIENT_CATEGORY: {
    TOPPING: 'Topping',
    SIZE: 'Size',
    SAUCE: 'Sauce',
    BASE: 'Base'
  }
}

export const UPSELL = {
  DESIGN_CODE: {
    INGREDIENT: 'IngredientUpsell',
    POSTER: 'Poster',
    CART: 'CartUpsell',
    PRODUCT: 'ProductUpsell'
  }
}

export const MISC = {
  LOCAL_STORAGE: {
    FAILPAYPALREDIRECT: 'failedPayPalRedirectRoute',
    FAILZIPREDIRECT: 'failedZipRedirectRoute',
    FAILAPPLEREDIRECT: 'failedAppleRedirectRoute',
    ZIPSIGNINLOCATION: 'zipSignInLocation',
    ISEASYCHECKOUT: 'isEasyCheckout',
    // Location where you will be redirected if there is failure
    FAILAMAZONREDIRECT: 'failedAmazonRedirectRoute',
    // Sign in during pay
    AMAZONSIGNINLOCATION: 'amazonSignInLocation'
  }
}

export const PAYMENT_DETAILS = {
  ZIP_CODES: {
    ZIP_PAY: '063',
    ZIP_MONEY: '064'
  },
  ZIP_PAYMENT_TYPES: {
    ZIP_PAY: 'Zip Pay',
    ZIP_MONEY: 'Zip Money'
  }
}

export const PAYMENT_TYPES = {
  ZIP_PAY: 'Zip Pay',
  APPLE_PAY: 'Apple Pay',
  CASH: 'Cash',
  FLEX: 'Flex',
  GOOGLE_PAY: 'Google Pay',
  MOBILE_EFTPOS: 'Mobile Eftpos',
  PAY_PAL: 'PayPal',
  VISA_CHECKOUT: 'Visa Checkout',
  AFTERPAY: 'Afterpay',
  PAY_PAL_PAY_IN_4: 'PayPal Pay in 4'
}

export const REALTIME_ORDER_STATUS = {
  PREPARING: 'Preparing',
  COOKING: 'Cooking',
  PACKING: 'Packing',
  READY: 'Ready',
  WITH_DRIVER: 'With Driver',
  DELIVERED: 'Delivered',
  UNABLE_TO_DELIVER: 'Unable to Deliver'
}

export const GA4_EVENT = {
  PAGE_VIEW: 'page_view_new',
  VIEW_ITEM: 'view_item',
  VIEW_ITEM_LIST: 'view_item_list',
  VIEW_PROMOTION: 'view_promotion',
  SELECT_PROMOTION: 'select_promotion',
  LOGIN: 'login',
  REGISTER: 'sign_up',
  SELECT_LOCATION: 'select_store',
  SELECT_DISPOSITION: 'select_disposition',
  ADD_TO_CART: 'add_to_cart',
  VIEW_CART: 'view_cart',
  BEGIN_CHECKOUT: 'begin_checkout',
  ADD_SHIPPING_INFO: 'add_shipping_info',
  ADD_PAYMENT_INFO: 'add_payment_info',
  PURCHASE: 'purchase',
  LOCALISATION_ERROR: 'localisation_error',
  LOCALISATION_MAP_CLICK: 'localisation_map_click',
  LOCALISATION_MAP_CLICK_ERROR: 'localisation_map_click_error',
  LOCALISATION_MAP_CLICK_SUCCESS: 'localisation_map_click_success',
  LOCALISATION_MAP_ORDER_CLICK: 'localisation_map_order_click',
  API_ERROR: 'api_error',
  REMOVE_FROM_CART: 'remove_from_cart',
  SELECT_VARIANT: 'select_variant',
  DESELECT_VARIANT: 'deselect_variant',
  GROUP_ORDER: 'group_order',
  CAMPAIGN_FORM_DISPLAY: 'campaign_form_display',
  CAMPAIGN_FORM_SUBMIT: 'campaign_form_submit',
  CAMPAIGN_FORM_COMPLETE: 'campaign_form_complete'
}

export const CUSTOMER_OPTIN_DEFAULTS = {
  EMAIL: false,
  SMS: false,
  SUPERCARS_FAN: false
}

export const SERVICE_TYPE = {
  DELIVERY: 'delivery',
  PICKUP: 'pickup',
  UNLOCALISED: 'unlocalised'
}

// Corresponds to the "no-win" prize id stored in Storyblok
export const PRIZE_NO_WIN_ID = 'no-win'

export const ENVIRONMENT = {
  BUS1: 'bus1',
  BUS2: 'bus2',
  BUS3: 'bus3',
  BUS4: 'bus4',
  DEVELOPMENT: 'development',
  UAT: 'uat',
  PRODUCTION: 'production'
}

export const UI = {
  LOCALISATION_POPOVER_TARGET: 'localisation-popover-target'
}
