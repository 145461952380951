import { defineStore } from 'pinia'

export const useCampaignStore = defineStore('campaign', {
  state: () => ({
    campaignExtras: {},
    campaignModal: false
  }),

  getters: {

  },

  actions: {
    setCampaignExtras ( payload) {
      this.campaignExtras = {
        ...this.campaignExtras,
        ...payload
      }
    },
    setCampaignModal (payload) {
      this.campaignModal = payload
    }
  }
})
