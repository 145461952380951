import { defineStore } from 'pinia'

import router from '@/router'
import CustomerApi from '@/services/api/customer.api'
import { profileDefaults, jsonWebTokenDefaults } from '@/stores/helpers/customer.defaults'
import { customer as basketCustomerDefaults } from '@/stores/helpers/cart.defaults'
import { getCookie } from '@/stores/helpers/cookie.helper'
import { dictionary } from '@/config/dictionary.config'
import { config } from '@/config/global.config'
import { SCOPE } from '@/config/account.config'
import { randomInteger } from '@/services/utility/random.utility'
import { isExpired } from '@/services/utility/date.utility'
import { pick } from '@/services/utility/object.utility'
import { TrackingController } from '@/controllers/tracking.controller'
import { UiController } from '@/controllers/ui.controller'
import { SessionController } from '@/controllers/session.controller'
import { ContentController } from '@/controllers/content.controller'
import { BasketController } from '@/controllers/basket.controller'
import { VaultController } from '@/controllers/vault.controller'
import { getLastPage } from '@/services/helpers/router.helper'
import { PROVIDER } from '@/config/provider.config.js'
import { setUser, addBreadcrumb } from '@/services/utility/sentry.utility.js'
import { logout as providerLogout, getToken as providerGetToken } from '@/services/helpers/provider.helper.js'
import { GA4_EVENT, MISC } from '@/config/constants.js'
import { isCapacitorNativePlatform } from '@/services/utility/capacitor.utility.js'
import { getCachedContentCards, requestContentCardsRefresh } from '@/services/utility/braze-web.utility'
import Recaptcha from '@/services/helpers/recaptcha.helper'
import { useCartStore } from '@/stores/cart'
import { useDiscoverStore } from '@/stores/discover'
import { useOrderStore } from '@/stores/order'
import { useUserStore } from '@/stores/user'
import { useStoreStore } from '@/stores/store'

export const useCustomerStore = defineStore('customer', {
  state: () => ({
    authExpiration: '',
    customer: {
      customerId: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      email: '',
      mobileNumber: '',
      profile: structuredClone(profileDefaults),
      cards: [],
      dispositionPreference: {
        address: '',
        serviceType: '',
        storeId: 0
      },
      hasEmailAccount: false,
      identities: [],
      savedCardId: '',
      expressPayMobileNumber: ''
    },
    jsonWebToken: structuredClone(jsonWebTokenDefaults),
    notifications: [],
    oAuth: {
      provider: '',
      token: '',
      firstName: '',
      lastName: ''
    },
    loginModal: {
      active: false,
      email: ''
    },
    savedPickupStore: '',
    savedDeliveryAddress: '',
    temporaryCustomerCheckoutInfo: {
      email: '',
      firstName: '',
      lastName: '',
      mobileNumber: ''
    },
    applePayInfo: {
      firstName: '',
      lastName: ''
    },
    callCenter: {
      mode: false,
      agentName: ''
    }
  }),

  getters: {
    isAuth (state) {
      return !!(
        state.jsonWebToken.access_token
      )
    },
    name (state) {
      return `${state.customer.profile.firstName} ${state.customer.profile.lastName}`
    },
    firstName (state) {
      return state.customer.profile.firstName
    },
    customerId (state) {
      return state.customer.customerId
    },
    userId (state) {
      return state.customer.userId
    },
    email (state) {
      return state.customer.email
    },
    savedCardId (state) {
      return state.customer.savedCardId
    },
    loggedIn (state) {
      return state.customer.customerId && state.customer.customerId !== '00000000-0000-0000-0000-000000000000'
    },
    profile (state) {
      return {
        customerId: state.customer.customerId,
        userId: state.customer.userId,
        firstName: state.customer.profile.firstName,
        lastName: state.customer.profile.lastName,
        mobileNumber: state.customer.mobileNumber,
        email: state.customer.email,
        optinEmail: state.customer.profile.optinEmail,
        optinSms: state.customer.profile.optinSms,
        optinSupercarsFan: state.customer.profile.optinSupercarsFan
      }
    },
    cards (state) {
      return state.customer.cards
    },
    defaultCard (state) {
      return state.customer.cards.find(x => x.default)
    },
    dispositionPreference (state) {
      return state.customer.dispositionPreference
    },
    authHeader (state) {
      return {
        Authorization: `Bearer ${state.jsonWebToken.access_token}`
      }
    },
    provider (state) {
      return state.oAuth.provider
    },
    isProviderAuth (state) {
      return !!(state.oAuth.provider)
    },
    hasEmailAccount (state) {
      return state.customer.hasEmailAccount
    },
    identities (state) {
      return state.customer.identities
    },
    hasIdentity (state) {
      return !!(state.customer.identities.length)
    },
    refreshToken (state) {
      return state.jsonWebToken.refresh_token
    },
    accessToken (state) {
      return state.jsonWebToken.access_token
    },
    loginModalActive (state) {
      return state.loginModal.active
    },
    loginModalEmail (state) {
      return state.loginModal.email
    },
    expressPayMobileNumber (state) {
      return state.customer.expressPayMobileNumber
    }
  },

  actions: {
    setCustomerData (payload) {
      this.customer = {
        ...this.customer,
        ...payload
      }
    },
    resetErrData () {
      this.notifications = []
    },
    // setAuthData (payload) {
    //   this.jsonWebToken = {
    //     ...jsonWebTokenDefaults,
    //     ...payload
    //   }
    // },

    // resetAuthData () {
    //   this.jsonWebToken = jsonWebTokenDefaults
    // },
    // setAuthExpiration (payload) {
    //   this.authExpiration = payload
    // },
    // resetAuthExpiration () {
    //   this.authExpiration = ''
    // },

    // resetCustomerData () {
    //   this.customer = {
    //     customerId: '00000000-0000-0000-0000-000000000000',
    //     userId: '00000000-0000-0000-0000-000000000000',
    //     email: '',
    //     mobileNumber: '',
    //     profile: profileDefaults,
    //     dispositionPreference: {
    //       address: '',
    //       serviceType: '',
    //       storeId: 0
    //     },
    //     hasEmailAccount: false,
    //     identities: [],
    //     cards: [],
    //     savedCardId: ''
    //   }
    // },
    // setCustomerProfile (payload) {
    //   this.customer.profile = {
    //     ...this.customer.profile,
    //     ...payload
    //   }
    // },
    // setErrData (payload) {
    //   this.notifications.push(payload)
    // },
    // removeErrData (payload) {
    //   this.notifications = this.notifications.filter(
    //     notification => notification.id !== payload
    //   )
    // },
    // setOAuth (payload) {
    //   this.oAuth = {
    //     ...this.oAuth,
    //     ...payload
    //   }
    // },
    // resetOAuth () {
    //   this.oAuth = {
    //     provider: '',
    //     token: '',
    //     firstName: '',
    //     lastName: ''
    //   }
    // },
    // setLoginModal (payload) {
    //   this.loginModal = payload
    // },
    // setSavedDeliveryAddressAndPickupStore (payload) {
    //   this.savedPickupStore = payload.savedPickupStore
    //   this.savedDeliveryAddress = payload.savedDeliveryAddress
    // },
    // setTemporaryCustomerCheckoutInfo (payload) {
    //   this.temporaryCustomerCheckoutInfo = {
    //     ...this.temporaryCustomerCheckoutInfo,
    //     ...payload
    //   }
    // },
    // setApplePayInfo (payload) {
    //   this.applePayInfo = {
    //     ...this.applePayInfo,
    //     ...payload
    //   }
    // },
    // setExpressPayMobileNumber (payload) {
    //   this.customer.expressPayMobileNumber = payload
    // },
    // setCallCenter (payload) {
    //   this.callCenter = {
    //     ...this.callCenter,
    //     ...payload
    //   }
    // },

    showLoginModal (payload) {
      // this.setLoginModal({
      //   active: true,
      //   email: payload?.email
      // })
      this.loginModal = {
        active: true,
        email: payload?.email
      }
    },
    hideLoginModal () {
      // this.setLoginModal({
      //   active: false,
      //   email: ''
      // })
      this.loginModal = {
        active: false,
        email: ''
      }
    },
    async getAccessToken () {
      if (!this.isAuth) {
        return
      }
      try {
        // Check access token expiry
        const expired = this.authExpiration && isExpired(this.authExpiration)
        if (!expired && this.accessToken) {
          // Return valid accessToken
          return this.accessToken
        } else if (expired && this.refreshToken) {
          // Refresh expired accessToken
          const res = await this.getRefreshToken()
          if (!res?.access_token) {
            throw Error('Failed to refresh token')
          }
          return res.access_token
        } else if (this.oAuth.token && this.oAuth.provider) {
          // Get new provider token else reuse stored token
          let providerToken = this.oAuth.token
          try {
            const res = await providerGetToken(this.oAuth.provider)
            if (res) {
              providerToken = res
            }
          } catch (err) {
            console.error(err)
          }

          // Attempt reauth with provider token
          const res = await this.providerLogin({
            payload: {
              provider: this.oAuth.provider,
              token: providerToken,
              nonce: window.localStorage.getItem('nonce')
            },
            redirect: false,
            silent: true
          })

          // Return token
          const token = res?.jsonWebToken?.access_token
          if (!token) {
            throw Error('Failed to get token by provider')
          }
          return token
        } else {
          throw Error('LOGIN_REQUIRED')
        }
      } catch (err) {
        // Require manual auth
        this.logout({
          userAction: 'expired'
        })
        throw err
      }
    },
    async logout (payload) {
      if (this.refreshToken && !(await VaultController.hasLockedData())) {
        this.revokeToken()
      }
      const root = '/'
      let toRoute = getLastPage({ includeCurrentPage: true, excludeRegex: /account.*/ }) || payload?.toRoute || root
      if (getCookie('zip-auth-access-token')) {
        document.cookie = 'zip-auth-access-token=; path=/; max-age=0;'
      }
      if (getCookie('amazon-auth-access-token')) {
        document.cookie = 'amazon-auth-access-token=; path=/; max-age=0;'
      }
      if (
        this.provider === PROVIDER.PAYPAL ||
        this.provider === PROVIDER.ZIP ||
        this.provider === PROVIDER.AMAZON
      ) {
        toRoute = root
      }
      localStorage.setItem(MISC.LOCAL_STORAGE.ISEASYCHECKOUT, false)
      localStorage.setItem(MISC.LOCAL_STORAGE.ZIPSIGNINLOCATION, null)
      localStorage.setItem(MISC.LOCAL_STORAGE.AMAZONSIGNINLOCATION, null)

      // this.resetAuthData()
      // this.resetCustomerData()
      // this.resetAuthExpiration()

      this.jsonWebToken = jsonWebTokenDefaults
      this.authExpiration = ''
      this.customer = {
        customerId: '00000000-0000-0000-0000-000000000000',
        userId: '00000000-0000-0000-0000-000000000000',
        email: '',
        mobileNumber: '',
        profile: profileDefaults,
        dispositionPreference: {
          address: '',
          serviceType: '',
          storeId: 0
        },
        hasEmailAccount: false,
        identities: [],
        cards: [],
        savedCardId: ''
      }

      useCartStore().setCustomerInfo(pick(['emailAddress', 'phoneNumber', 'firstName', 'lastName', 'customerId', 'userId'], basketCustomerDefaults))
      useOrderStore().setRecentOrders([])
      useOrderStore().setMostRecentOrderDisplay(true)

      this.handleProviderLogout()
      this.handleSuccess({
        userAction: payload?.userAction || 'logged out',
        toRoute,
        redirect: payload?.redirect || !!(toRoute)
      })
      setUser(null)
      this.resetErrData()

      VaultController.lockVault()
    },
    async providerLogin ({ payload, redirect = true, silent = false }) {
      UiController.showSpinner()

      addBreadcrumb({
        category: 'customer',
        message: `Logging in - ${payload.provider}`
      })
      const provider = payload.provider
      // this.setOAuth({
      //   provider: payload.provider,
      //   token: payload.token,
      //   firstName: payload.firstName,
      //   lastName: payload.lastName
      // })

      this.oAuth = {
        ...this.oAuth,
        provider: payload.provider,
        token: payload.token,
        firstName: payload.firstName,
        lastName: payload.lastName
      }

      TrackingController.trackLegacyEvent('socialLogin', {
        provider: payload.provider
      })

      this.resetErrData()

      const apiRoot = useDiscoverStore().uris.API_CUSTOMER

      const scope = [SCOPE.READ_PROFILE, SCOPE.WRITE_PROFILE, SCOPE.READ_CARD, SCOPE.WRITE_CARD]

      if (
        payload.rememberLogin || isCapacitorNativePlatform()
      ) {
        scope.push(SCOPE.OFFLINE_ACCESS)
      }
      try {
        // Social Login
        const response = await CustomerApi.OAuthLogin({
          apiRoot,
          data: {
            ...payload,
            scope: scope.join(' ')
          }
        })
        response.data.provider = provider
        const zipToken = response.data.zipToken
        if (zipToken) {
          document.cookie = `zip-auth-access-token=${zipToken.access_token}; path=/; max-age=${Number(zipToken.expires_in) * 1000}`
        }
        const amazonToken = response.data.amazonToken
        if (amazonToken) {
          document.cookie = `amazon-auth-access-token=${amazonToken.access_token}; path=/; max-age=${Number(amazonToken.expires_in) * 1000}`
        }
        this.handleLogInSuccess({ payload: response, redirect, silent })
        return response.data
      } catch (err) {
        console.error(err)
        if (err?.response?.status === 302) {
          UiController.showSpinner({ loading: false })
          if (payload.provider === PROVIDER.ZIP) {
            const url = err?.response?.data
            // eslint-disable-next-line prefer-const
            let parser = document.createElement('a')
            parser.href = url
            const params = new URL(url).searchParams
            // eslint-disable-next-line camelcase
            const access_token = params.get('access_token')
            // eslint-disable-next-line camelcase
            const expires_in = params.get('expires_in')
            document.cookie = `zip-auth-access-token=${access_token}; path=/; max-age=${Number(expires_in) * 1000}`
            return router.push({ path: parser.pathname })
          } else if (payload.provider === PROVIDER.AMAZON) {
            const url = err?.response?.data
            // eslint-disable-next-line prefer-const
            let parser = document.createElement('a')
            parser.href = url
            const params = new URL(url).searchParams
            // eslint-disable-next-line camelcase
            const access_token = params.get('access_token')
            // eslint-disable-next-line camelcase
            const expires_in = params.get('expires_in')
            document.cookie = `amazon-auth-access-token=${access_token}; path=/; max-age=${Number(expires_in) * 1000}`
            return router.push({ path: parser.pathname })
          }
          return router.push({ path: '/oauth-register' })
        } else if (err?.response?.data?.code === 'ExistingAccount') {
          UiController.showSpinner({ loading: false })
          throw err
        }
        throw err
      }
    },
    async login (payload) {
      UiController.showSpinner()
      addBreadcrumb({
        category: 'customer',
        message: 'Logging in'
      })

      this.resetErrData()

      const apiRoot = useDiscoverStore().uris.API_CUSTOMER

      const scope = [SCOPE.READ_PROFILE, SCOPE.WRITE_PROFILE, SCOPE.READ_CARD, SCOPE.WRITE_CARD]

      if (
        payload.rememberLogin || isCapacitorNativePlatform()
      ) {
        scope.push(SCOPE.OFFLINE_ACCESS)
      }
      try {
        const response = await CustomerApi.Login({
          apiRoot,
          data: {
            email: payload.email,
            password: payload.password,
            scope: scope.join(' '),
            tokenV3: payload.tokenV3
          }
        })

        this.handleLogInSuccess({ payload: response, redirect: payload.redirect })
      } catch (err) {
        if (err?.response?.data?.code === 'UnverifiedEmail') {
          UiController.showSpinner({ loading: false })
          return router.push(`/verify-email?email=${encodeURIComponent(payload.email)}`)
        }
        this.handleErrors(err)
        throw err
      }
    },
    async handleLogInSuccess ({ payload, redirect, silent }) {      try {
        const unlockMode = await VaultController.getUnlockMode()
        const canUseBiometrics = await VaultController.canUseBiometrics()

        // Show popup for biometrics
        if (canUseBiometrics && !(unlockMode === 'BiometricsWithPasscode' || unlockMode === 'Biometrics')) {
          await VaultController.loginAttempt()
        }

        // Clear the session first
        await VaultController.clearSession()

        // Set the session
        await VaultController.setSession({
          jsonWebToken: { ...payload.data.jsonWebToken },
          customer: payload.data.customer
        })

        // Remove the refresh token
        const unlockModeLogin = await VaultController.getUnlockMode()
        if (
          isCapacitorNativePlatform() &&
          unlockModeLogin === 'SecureStorage' &&
          !payload.rememberLogin
        ) {
          payload.data.jsonWebToken.refresh_token = ''
        }

        // this.setAuthData(payload.data.jsonWebToken)
        this.jsonWebToken = {
          ...jsonWebTokenDefaults,
          ...payload.data.jsonWebToken
        }

        this.setCustomerData(payload.data.customer)

        // Save userId and push to datalayer
        useUserStore().setUser(payload.data.customer.userID)

        // Sentry Set Authenticated User Info
        setUser({
          id: payload.data.customer.customerId,
          email: payload.data.customer.email
        })

        // Autofill Customer Disposition on Login
        const basketPayload = BasketController.getPayloadWithCustomerDisposition()

        let route = '/'
        if (payload.data.provider === PROVIDER.PAYPAL) {
          route = localStorage.getItem(MISC.LOCAL_STORAGE.FAILPAYPALREDIRECT) === '/login' ? '/' : localStorage.getItem(MISC.LOCAL_STORAGE.FAILPAYPALREDIRECT)
        } else if (payload.data.provider === PROVIDER.ZIP) {
          route = localStorage.getItem(MISC.LOCAL_STORAGE.FAILZIPREDIRECT) === '/login' ? '/' : localStorage.getItem(MISC.LOCAL_STORAGE.FAILZIPREDIRECT)
        } else if (payload.data.provider === PROVIDER.AMAZON) {
          route = localStorage.getItem(MISC.LOCAL_STORAGE.FAILAMAZONREDIRECT) === '/login' ? '/' : localStorage.getItem(MISC.LOCAL_STORAGE.FAILAMAZONREDIRECT)
        } else if (payload.data.provider === PROVIDER.APPLE) {
          route = localStorage.getItem(MISC.LOCAL_STORAGE.FAILAPPLEREDIRECT) === '/login' ? '/' : localStorage.getItem(MISC.LOCAL_STORAGE.FAILAPPLEREDIRECT)
        } else {
          route = getLastPage({ includeCurrentPage: false, excludeRegex: /login/ })
          if (route === '/register') {
            route = '/offers'
          } else if (route === '/login' || !route) {
            route = '/'
          }
        }

        // track social provider
        TrackingController.trackLegacyEvent('LoginSucsess', {
          socialProvider: payload.data.provider
        })

        // GA4 tracking for login
        TrackingController.trackGA4Event(GA4_EVENT.LOGIN, { name: 'login' })

        await this.handleSuccess({ userAction: 'logged in', silent: silent || (basketPayload && useCartStore().validBasket), toRoute: route, redirect })
        addBreadcrumb({
          category: 'customer',
          message: 'Logged in'
        })
        let lastPage = getLastPage({ includeCurrentPage: true, excludeRegex: /login/ })
        if ((payload.data.provider === PROVIDER.PAYPAL || payload.data.provider === PROVIDER.ZIP || payload.data.provider === PROVIDER.APPLE ||
          payload.data.provider === PROVIDER.AMAZON) && redirect) {
          lastPage = route // Use the route where the user will land after logging in
        }
        const blacklisted = config.DISPOSITION_OVERWRITE_BLACKLIST.some(x => lastPage?.includes(x))
        if (basketPayload && !blacklisted) {
          await this.getSavedDeliveryAddressAndPickupStore({ pickup: basketPayload, deliveryAddress: `${this.dispositionPreference?.address?.address}, ${this.dispositionPreference?.address?.city}` })
          if (useCartStore().validBasket) {
            UiController.showSnackbar({
              active: true,
              message: dictionary.CUSTOMER.OVERWRITE_DISPOSITION(useCartStore().basketServiceType, basketPayload.serviceType, this.savedDeliveryAddress, this.savedPickupStore)?.text,
              theme: 'success',
              hasCloseIcon: false,
              autoClose: false,
              blocking: true,
              okayButton: {
                active: true,
                text: 'Yes, please change',
                theme: 'secondary',
                callback: async () => {
                  UiController.showSnackbar({ active: false })
                  UiController.showSpinner({ loading: true })
                  await SessionController.saveSession({ payload: basketPayload, silent: false })
                  UiController.showSpinner({ loading: false })
                }
              },
              cancelButton: {
                active: true,
                text: 'No thanks',
                theme: 'danger',
                callback: () => {
                  UiController.showSnackbar({
                    active: false
                  })
                }
              }
            })
          } else {
            UiController.showSpinner({ loading: true })
            await SessionController.saveSession({ payload: basketPayload })
            UiController.showSpinner({ loading: false })
          }
        }

        /**
         * Set Auth Expiration to expiry UNIX timestamp returned by Customer API
         * */
        // this.setAuthExpiration()
        this.authExpiration = new Date(payload.data.jsonWebToken.expires_in * 1000)

        // Content Cards
        requestContentCardsRefresh(() => {
          getCachedContentCards()
        })
      } catch (err) {
        this.handleErrors(err)
        throw err
      }
    },
    async register ({ customerInfo, password, registerType, redirect }) {
      const userStore = useUserStore()
      UiController.showSpinner()

      addBreadcrumb({
        category: 'customer',
        message: 'Registering'
      })

      this.resetErrData()
      try {
        const settings = useDiscoverStore().settings
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER

        const scope = [SCOPE.READ_PROFILE, SCOPE.WRITE_PROFILE, SCOPE.READ_CARD, SCOPE.WRITE_CARD]

        await Recaptcha.init(settings.GRECAPTCHA)
        const tokenV3 = await Recaptcha.execute(settings.GRECAPTCHA, dictionary.RECAPTCHA.ACTION.REGISTER)

        const response = await CustomerApi.Register({
          apiRoot,
          data: {
            scope: scope.join(' '),
            password,
            tokenV3,
            registerType,
            email: customerInfo.email,
            mobileNumber: customerInfo.mobileNumber,
            profile: {
              firstName: customerInfo.firstName,
              lastName: customerInfo.lastName,
              optinEmail: customerInfo.optinEmail,
              optinSms: customerInfo.optinSms,
              optinSupercarsFan: customerInfo.optinSupercarsFan
            }
          }
        })

        const { customerId, userId } = response.data

        addBreadcrumb({
          category: 'customer',
          message: 'Registered'
        })

        // Save userId and push to datalayer
        userStore.setUser(userId)

        this.handleSuccess({ userAction: 'registered', redirect, toRoute: `/verify-email/${customerId}?email=${customerInfo.email}` })
      } catch (err) {
        this.handleErrors(err)
        throw err
      }
    },
     async getRefreshToken (refreshToken) {
      try {
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER

        const response = await CustomerApi.RefreshToken({
          apiRoot,
          data: {
            refresh_token: this.refreshToken || refreshToken
          }
        })

        // this.setAuthData(payload.data.jsonWebToken)
        // this.setAuthExpiration(new Date(response.data.expires_in * 1000))

        this.jsonWebToken = {
          ...jsonWebTokenDefaults,
          ...response.data.jsonWebToken
        }
        this.authExpiration = new Date(response.data.expires_in * 1000)

        return response.data
      } catch (err) {
        this.logout({
          userAction: 'expired'
        })
        throw err
      }
    },
    async revokeToken () {
      try {
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER

        await CustomerApi.RevokeToken({
          apiRoot,
          data: {
            refresh_token: this.refreshToken
          }
        })
      } catch (err) {
        this.handleErrors(err)
        throw err
      }
    },
    async recaptchaValidate (payload) {
      try {
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER

        // Might seem a bit funky to return the `await` expression
        // but this allows the resolved Promise object to be
        // "then"-able on a success, but handles the rejected
        // Promise object to be caught by the below catch block

        return await CustomerApi.VerifyRecaptchaToken({
          apiRoot,
          data: payload
        })
      } catch (err) {
        this.handleErrors(err)
      }
    },
    async requestPasswordResetToken (payload) {
      UiController.showSpinner()
      try {
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER
        await CustomerApi.RequestPasswordResetToken({
          apiRoot,
          data: payload
        })

        this.handleSuccess({ userAction: 'password-reset-request' })
      } catch (err) {
        this.handleErrors(err)
      }
    },
    async verifyPasswordResetToken (payload) {
      UiController.showSpinner()
      try {
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER
        const data = {
          customerId: payload.customerId,
          token: payload.token
        }

        /**
         * Seems a bit funky, but `return await` guarantees that rejected
         * Promise objects will be handled by the catch statement below,
         * and resolved Promise objects are "then-able" within the context
         * they are executed in
         * */

        return await CustomerApi.VerifyPasswordResetToken({ apiRoot, data })
      } catch (err) {
          this.handleErrors(err)
          UiController.showSnackbar({
              active: true,
              message: this.notifications.length ? this.notifications[0].message : err,
              theme: 'danger',
              autoClose: false
          })
          router.push('/reset-password') // route back to token request form
      }
    },
    async resetPassword (data) {
      UiController.showSpinner()
      try {
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER
        await CustomerApi.ResetPassword({ apiRoot, data })
        this.handleSuccess({
          userAction: 'reset your password',
          toRoute: { name: 'login' }
        })
      } catch (err) {
        this.handleErrors(err)
      }
    },
    async getProfile (customerId) {
      const apiRoot = useDiscoverStore().uris.API_CUSTOMER
      try {
        await this.getAccessToken()
        const response = await CustomerApi.GetProfile({
          apiRoot,
          customerId: customerId ?? this.customerId,
          options: {
            headers: this.authHeader
          }
        })

        this.setCustomerData(response.data)
        return response.data
      } catch (err) {
        console.error(err)
      }
    },
    async getZipTokenDetails (data) {

      const apiRoot = useDiscoverStore().uris.API_CUSTOMER
      try {
        await this.getAccessToken()
        const response = await CustomerApi.ZipToken({
          apiRoot,
          data
        })
        return response.data
      } catch (err) {
        console.error(err)
      }
    },
    async updateProfile (payload) {
      UiController.showSpinner()
      try {
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER

        const data = {
          email: payload.email,
          mobileNumber: payload.mobileNumber,
          profile: {
            firstName: payload.firstName,
            lastName: payload.lastName,
            name: payload.name,
            optinEmail: payload.optinEmail,
            optinSms: payload.optinSms,
            optinSupercarsFan: payload.optinSupercarsFan,
            acceptTerms: payload.acceptTerms,
            attributes: this.customer.profile.attributes
          }
        }

        await this.getAccessToken()

        /**
         * Since the API only returns a success/error status and
         * we already serialize the data into a shape that reflects
         * what gets stored in the store, we can update local data
         * on success of the API request
         * */

        await CustomerApi.UpdateProfile({
          apiRoot,
          customerId: this.customerId,
          data,
          options: {
            headers: this.authHeader
          }
        })

        this.setCustomerData(data)
        const customerInfo = {
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
          phoneNumber: payload.mobileNumber,
          optinEmail: payload.optinEmail,
          optinSms: payload.optinSms,
          optinSupercarsFan: payload.optinSupercarsFan
        }
        useCartStore().setCustomerInfo(customerInfo)
        this.handleSuccess({ userAction: 'updated your profile', redirect: false })
      } catch (err) {
        this.handleErrors(err)
      }
    },
    async deleteProfile () {
      try {
        UiController.showSpinner()
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER
        await CustomerApi.DeleteProfile({
          apiRoot,
          customerId: this.customerId,
          options: {
            headers: this.authHeader
          }
        })

        await VaultController.clearSession()
        await this.logout({ userAction: 'deleted your account' })
      } catch (err) {
        this.handleErrors(err)
      }
    },
    async updatePassword (data){
      UiController.showSpinner()
      try {
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER
        await CustomerApi.UpdatePassword({
          apiRoot,
          customerId: this.customerId,
          data,
          options: {
            headers: this.authHeader
          }
        })

        this.handleSuccess({ userAction: 'updated your password' })
      } catch (err) {
        this.handleErrors(err)
      }
    },
    async saveCreditCard (data) {
      try {
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER

        await this.getAccessToken()

        const response = await CustomerApi.SaveCreditCard({
          apiRoot,
          customerId: this.customerId,
          data,
          options: {
            headers: this.authHeader
          }
        })

        if (!data.flag) {
          this.setCustomerData(response.data)
        } else {
          this.setCustomerData({
            savedCardId: response.data.cardId
          })
        }
      } catch (err) {
        this.handleErrors(err)
      }
    },
    async removeCreditCard (cardId) {
      UiController.showSpinner()
      try {
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER
        const response = await CustomerApi.RemoveCreditCard({
          apiRoot,
          customerId: this.customerId,
          cardId,
          options: {
            headers: this.authHeader
          }
        })

        this.setCustomerData(response.data)
        this.handleSuccess({ userAction: 'removed your card', redirect: false })
      } catch (err) {
        this.handleErrors(err)
      }
    },
    async updateSavedCardStatus (data) {
      try {
        if (this.customer.savedCardId) {
          const apiRoot = useDiscoverStore().uris.API_CUSTOMER

          await this.getAccessToken()

          const response = await CustomerApi.UpdateCardStatus({
            apiRoot,
            customerId: this.customerId,
            cardId: this.customer.savedCardId,
            data,
            options: {
              headers: this.authHeader
            }
          })

          this.setCustomerData({ ...response.data, savedCardId: '' })
        }
      } catch (err) {
        this.handleErrors(err)
      }
    },
    async updateCardStatus ({ cardId, data }) {
      try {
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER

        await this.getAccessToken()

        const response = await CustomerApi.UpdateCardStatus({

          apiRoot,
          customerId: this.customerId,
          cardId,
          data,
          options: {
            headers: this.authHeader
          }
        })
        this.setCustomerData(response.data)
      } catch (err) {
        this.handleErrors(err)
        throw err
      }
    },
    async updateDefaultCard ({ cardId, data }) {
      UiController.showSpinner()
      try {
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER
        await this.getAccessToken()

        const response = await CustomerApi.UpdateDefaultCard({
          apiRoot,
          customerId: this.customerId,
          cardId,
          data,
          options: {
            headers: this.authHeader
          }
        })

        this.setCustomerData(response.data)
        this.handleSuccess({ userAction: 'set your default card', redirect: false })
      } catch (err) {
        this.handleErrors(err)
        throw err
      }
    },
    async getCreditCard (cardId) {
      try {
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER

        await this.getAccessToken()

        const response = await CustomerApi.GetCreditCard({
          apiRoot,
          customerId: this.customerId,
          cardId,
          options: {
            headers: this.authHeader
          }
        })

        return response.data
      } catch (err) {
        this.handleErrors(err)
        throw err
      }
    },
    async updateDeliveryAddress ({ data, silent = false }) {
      try {
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER

        await this.getAccessToken()

        const response = await CustomerApi.UpdateDeliveryAddress({
          apiRoot,
          customerId: this.customerId,
          data,
          options: {
            headers: this.authHeader
          }
        })

        this.setCustomerData(response.data)
        this.handleSuccess({ userAction: 'saved your delivery address', redirect: false, silent })

        return response.data
      } catch (err) {
        this.handleErrors(err)
        throw err
      }
    },
    async removeDeliveryAddress () {
      try {
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER

        await this.getAccessToken()

        const response = await CustomerApi.RemoveDeliveryAddress({
          apiRoot,
          customerId: this.customerId,
          options: {
            headers: this.authHeader
          }
        })

        this.setCustomerData(response.data)
        this.handleSuccess({ userAction: 'removed your delivery address', redirect: false })
      } catch (err) {
        this.handleErrors(err)
        throw err
      }
    },
    async updatePickupStore ({ data, silent = false }) {
      try {
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER

        await this.getAccessToken()

        const response = await CustomerApi.UpdatePickupStore({
          apiRoot,
          customerId: this.customerId,
          data,
          options: {
            headers: this.authHeader
          }
        })

        this.setCustomerData(response.data)
        this.handleSuccess({ userAction: 'saved your pickup store', redirect: false, silent })
      } catch (err) {
        this.handleErrors(err)
        throw err
      }
    },
    async removePickupStore () {
      try {
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER

        await this.getAccessToken()

        const response = await CustomerApi.RemovePickupStore({
          apiRoot,
          customerId: this.customerId,
          options: {
            headers: this.authHeader
          }
        })

        this.setCustomerData(response.data)
        this.handleSuccess({ userAction: 'removed your pickup store', redirect: false })
      } catch (err) {
        this.handleErrors(err)
        throw err
      }
    },
    async updateServiceType (data) {
      try {
        const apiRoot = useDiscoverStore().uris.API_CUSTOMER

        await this.getAccessToken()

        const response = await CustomerApi.UpdateServiceType({
          apiRoot,
          customerId: this.customerId,
          data,
          options: {
            headers: this.authHeader
          }
        })

        this.setCustomerData(response.data)
        this.handleSuccess({ userAction: 'updated your preferred order type', redirect: false })
      } catch (err) {
        this.handleErrors(err)
        throw err
      }
    },
    async sendVerifyEmail (payload) {
      const apiRoot = useDiscoverStore().uris.API_CUSTOMER
      return CustomerApi.SendVerifyEmail({ apiRoot, data: payload })
    },
    async verifyEmail (payload) {
      const apiRoot = useDiscoverStore().uris.API_CUSTOMER
      return CustomerApi.VerifyEmail({ apiRoot, data: payload })
    },
    async getVouchers () {
      const apiRoot = useDiscoverStore().uris.API_CUSTOMER
      try {
        await this.getAccessToken()
        const response = await CustomerApi.GetVouchers({
          apiRoot,
          customerId: this.customerId,
          options: {
            headers: this.authHeader
          }
        })

        return response.data
      } catch (err) {
        console.error(err)
      }
    },
    checkIfAuthIsExpired () {
      return this.getAccessToken()
        .catch(err => console.error(err))
    },
    async handleSuccess ({ userAction, redirect = true, toRoute, silent = false }) {

      if (!silent) {
        if (userAction === 'expired') {
          UiController.showSnackbar({
            active: true,
            message: ContentController.dictionaryToMessage('CUSTOMER.SESSION.EXPIRED'),
            theme: 'warning',
            autoClose: false
          })
        } else if (userAction === 'idleExpiry') {
          UiController.showSnackbar({
            active: true,
            message: ContentController.dictionaryToMessage('CUSTOMER.SESSION.IDLEEXPIRY'),
            theme: 'warning',
            autoClose: false
          })
        } else if (userAction !== 'account locked') {
          UiController.showSnackbar({
            message: userAction === 'password-reset-request'
              ? 'If your email has a Pizza Hut account associated, you will receive an email with instructions.'
              : `You have succesfully ${userAction}`,
            active: true
          })
        }
      }

      // Only dissappear when directed
      UiController.showSpinner({
        loading: false
      })

      if (redirect && toRoute) {
        router.push(toRoute).catch(() => {})
      } else if (redirect) {
        router.go(-1)
      }
    },
    async handleErrors (payload) {
      let notification = {
        id: String(Date.now() * randomInteger()),
        variant: 'danger',
        message: ''
      }

      const handleError = notification => {
        UiController.showSpinner({
          loading: false
        })
        // this.setErrData(notification)
        this.notifications.push(notification)
      }

      const showGenericError = () => {
        notification = {
          ...notification,
          message: dictionary.CUSTOMER.ERROR.text
        }
        handleError(notification)
      }

      if (!payload) {
        return showGenericError()
      }

      // Forbidden error handled by axios interceptor
      if (payload.response?.status === 403) {
        UiController.showSpinner({
          loading: false
        })
        return
      } else if (payload.response?.status === 500) {
        return showGenericError()
      }
      const error = payload?.response?.data || payload?.response || payload

      if (error?.message) {
        notification.message = ContentController.codeToMessage(error.code, error.message)
      } else if (typeof error === 'string' && error.length) {
        notification.message = error
      } else if (error && error.errors && error.errors.length) {
        notification.message = error.errors[0].message
      } else if (error) {
        notification = {
          ...notification,
          ...payload
        }
      } else {
        notification = {
          ...notification,
          message: dictionary.CUSTOMER.ERROR.text
        }
      }

      handleError(notification)
    },
    clearErrorNotifications () {
      this.resetErrData()
    },
    removeErrorNotification (payload) {
      // this.removeErrData(payload)
      this.notifications = this.notifications.filter(
        notification => notification.id !== payload
      )
    },
    async handleProviderLogout () {
      try {
        const oAuth = this.oAuth
        // this.resetOAuth()
        this.oAuth = {
          provider: '',
          token: '',
          firstName: '',
          lastName: ''
        }
        return providerLogout(oAuth.provider)
      } catch (err) {
        console.log('handleProviderLogout error')
        this.handleErrors(err)
        throw err
      }
    },
    async getSavedDeliveryAddressAndPickupStore (payload) {
      let savedPickupStore = ''
      const savedDeliveryAddress = payload.deliveryAddress
      if (payload.pickup.storeId) {
        await useStoreStore().getStoreByCode(payload.pickup.storeId).then((res) => {
          savedPickupStore = `${res.store.city}, ${res.store.state}`
          // this.setSavedDeliveryAddressAndPickupStore({ savedPickupStore, savedDeliveryAddress })
          this.savedPickupStore = savedPickupStore
          this.savedDeliveryAddress = savedDeliveryAddress
        })
      } else {
        // this.setSavedDeliveryAddressAndPickupStore({ savedPickupStore, savedDeliveryAddress })
        this.savedPickupStore = savedPickupStore
        this.savedDeliveryAddress = savedDeliveryAddress
      }
    },
    setTemporaryCustomerCheckoutInfo (payload) {
      this.temporaryCustomerCheckoutInfo = {
        ...this.temporaryCustomerCheckoutInfo,
        ...payload
    }
    },
    setCustomerProfile (payload) {
      this.customer.profile = {
        ...this.customer.profile,
        ...payload
      }
    },
    setApplePayInfo (payload) {
      this.applePayInfo = {
        ...this.applePayInfo,
        ...payload
      }
    },
    setExpressPayMobileNumber (payload) {
      this.customer.expressPayMobileNumber = payload
    },
    setCallCenter (payload) {
      this.callCenter = {
        ...this.callCenter,
        ...payload
      }
    }
  }
})
