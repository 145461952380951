import { createApp, h, reactive, defineAsyncComponent } from 'vue'
import { VueHeadMixin, createHead } from '@unhead/vue'
import App from './App.vue'
import router from './router'
import StoryblokClient from 'storyblok-js-client'
import { init as initFullStory } from '@fullstory/browser'
import { Capacitor } from '@capacitor/core'
import VueCookies from 'vue3-cookies'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'

import FontAwesomeIcon from './vendor/fontAwesome'
import * as filters from './filters'
import './vendor/dayjs'
import sentryFullStoryPlugin from './vendor/SentryFullStory'
import './services/interceptors/axios.response.interceptor'

const head = createHead()

export function start ({ configuration }) {
  /**
   * Initialize Vue instance with existing router/store data & mount to DOM.
   * Before instance creation, resolve router imports.
   *
   * Previously, data that had been fetched from an API was stuck in limbo
   * preventing processes that referenced data from the store. Since
   * `addRoute` allows us to dynamically update routes after the router
   * has been instantiated, there was no reason to make the rest of the App's
   * processes wait around for data that had already been fetched.
   *
   * Moving the route evaluation/resolution logic into the `beforeCreate`
   * hook allows it to occur before anything important happens, but gives
   * access to the Pinia store on the Vue instance for any processes that may
   * need to reference it.
   * */

  // Fullstory
  initFullStory({
    orgId: import.meta.env.VITE_FULLSTORY_ORG_ID,
    debug: true,
    devMode: import.meta.env.VITE_ENV !== 'production',
    ...(Capacitor.isNativePlatform() && ({
      assetMapId: Capacitor.getPlatform() === 'ios'
        ? configuration.settings.FULLSTORY_IOS_ASSET_MAP_ID
        : configuration.settings.FULLSTORY_ANDROID_ASSET_MAP_ID
    }))
  })

  // Storyblok
  const Storyblok = new StoryblokClient({
    accessToken: configuration.uris.API_CMS_KEY,
    version: 'published',
    cv: new Date().getTime()
  })

  Storyblok.setComponentResolver((component, blok) => {
    return `<component blok='${JSON.stringify(blok)}' is="${component}"></component>`
  })

  window.Storyblok = Storyblok

  const app = createApp({
    render () {
      return h(App, {
        configuration
      })
    }
  })

  const pinia = createPinia()
  pinia.use(createPersistedState({
    auto: true,
    key: id => `pinia_${id}`,
  }))

  app.use(head)
  app.mixin(VueHeadMixin)
  app.use(router)
  app.use(pinia)
  app.use(FontAwesomeIcon)
  app.use(VueCookies)
  app.use(sentryFullStoryPlugin)

  Object.values(filters).forEach(filter => app.use(filter))

  window.app = app

  app.config.globalProperties.$abtests = reactive({
    'cctt-0': 0,
    'cctt-1': 0,
    'cctt-2': 0,
    'cctt-3': 0,
    'cctt-4': 0,
    'cctt-5': 0,
    'cctt-6': 0,
    'cctt-7': 0,
    'cctt-8': 0,
    'cctt-17': 0,
    'cctt-9': 0,
    'cctt-10': 0,
    'cctt-11': 0,
    'cctt-12': 0,
    'cctt-13': 0,
    'cctt-14': 0,
    'cctt-15': 1,
    'cctt-16': 0,
    'abtest-localisation-modal-postcode-errors': 0,
    'abtest-localisation-before-starting-order': 0
})

  window.abtests = app.config.globalProperties.$abtests

  window.abtests = new Proxy(window.abtests, {
    set (target, property, value) {
      target[property] = value
      app.config.globalProperties.$abtests[property] = value
      return true
    }
  })

  window.tests = {
    showWingsDate: 0,
    isWed: 0,
    isTest: 0,
    showSpinBgVariant: true
  }

  app.config.performance = import.meta.env.DEV
  app.config.globalProperties.$tests = window.tests
  app.config.globalProperties.$svcparameter = window.svcparameter

  app.component('MarkdownLite', defineAsyncComponent(() => import('@/components/shared/MarkdownLite.vue')))

  app.mount('#app-root')
}
